export const emptyResponse = {
  status: 200, // You can adjust this if needed
  statusText: '', // Add any meaningful text if necessary
  data: {
    data: [],
    included: [],
    meta: {
      page: 1,
      perPage: 10,
      totalItems: 0,
      totalPages: 0,
    },
  },
};
