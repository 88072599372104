import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

import Bottleneck from 'bottleneck';
import { emptyResponse } from './utils';
import mapLimit from 'async/mapLimit';
import { queue, retry } from 'async';
import { message } from 'antd';

// Create a new rate limiter
const limiter = new Bottleneck({
  maxConcurrent: 100, // Allow up to 100 concurrent requests
  minTime: 50, // 50ms between requests (adjust to your API's capabilities)
  reservoir: 500, // Allow up to 500 requests in the first batch
  reservoirRefreshAmount: 500, // Refill 500 requests
  reservoirRefreshInterval: 60 * 1000, // Refill every 60 seconds (adjust to API rate limits)
});
limiter.on('failed', async (error, jobInfo) => {
  if (error.response && error.response.status === 429) {
    console.warn(`Rate limit hit. Retrying after ${jobInfo.retryCount} retries.`);
    if (jobInfo.retryCount < 3) {
      // Retry up to 3 times
      return 2000; // Retry after 2 seconds on 429 Too Many Requests error
    }
  }
  return null;
});

// Helper function to sort transactions by the latest message date
const sortedTransactionsByLatestMessage = txs =>
  reverse(
    sortBy(txs, tx => {
      const latestMessageDate =
        tx?.attributes?.latestMessageDate || tx?.attributes?.lastTransitionedAt;
      return latestMessageDate ? new Date(latestMessageDate).getTime() : null;
    })
  );

// ================ Action types ================ //

export const FETCH_MESSAGES_REQUEST = 'app/InboxPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/InboxPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/InboxPage/FETCH_MESSAGES_ERROR';

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_COMPLETED_ORDERS_REQUEST = 'app/InboxPage/FETCH_COMPLETED_ORDERS_REQUEST';
export const FETCH_COMPLETED_ORDERS_SUCCESS = 'app/InboxPage/FETCH_COMPLETED_ORDERS_SUCCESS';
export const FETCH_COMPLETED_ORDERS_ERROR = 'app/InboxPage/FETCH_COMPLETED_ORDERS_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchCompletedOrdersError: null,
  fetchOrdersOrSalesError: null,
  fetchMessagesError: null, // Add fetch error state for messages
  pagination: null,
  transactionRefs: [], // For orders and sales
  messageRefs: [], // For messages
  completedOrderRefs: [], // For completed orders
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_COMPLETED_ORDERS_REQUEST:
    case FETCH_ORDERS_OR_SALES_REQUEST:
    case FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchOrdersOrSalesError: null,
        fetchMessagesError: null,
      };

    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = payload.data.data;
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions), // Orders or sales
        pagination: payload.data.meta,
      };
    }

    case FETCH_COMPLETED_ORDERS_SUCCESS: {
      const transactions = payload.data.data;
      return {
        ...state,
        fetchInProgress: false,
        completedOrderRefs: entityRefs(transactions), // Completed orders
        pagination: payload.data.meta,
      };
    }

    case FETCH_MESSAGES_SUCCESS: {
      const transactions = sortedTransactionsByLatestMessage(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        messageRefs: entityRefs(transactions), // Messages
        pagination: payload.data.meta,
      };
    }

    case FETCH_ORDERS_OR_SALES_ERROR:
    case FETCH_COMPLETED_ORDERS_ERROR:
    case FETCH_MESSAGES_ERROR:
      console.error(payload); // eslint-disable-line
      return {
        ...state,
        fetchInProgress: false,
        fetchOrdersOrSalesError: type === FETCH_ORDERS_OR_SALES_ERROR ? payload : null,
        fetchCompletedOrdersError: type === FETCH_COMPLETED_ORDERS_ERROR ? payload : null,
        fetchMessagesError: type === FETCH_MESSAGES_ERROR ? payload : null,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = response => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: response,
});
const fetchMessagesError = e => ({
  type: FETCH_MESSAGES_ERROR,
  error: true,
  payload: e,
});

// Action creators for Completed Orders
const fetchCompletedOrdersRequest = () => ({ type: FETCH_COMPLETED_ORDERS_REQUEST });
const fetchCompletedOrdersSuccess = response => ({
  type: FETCH_COMPLETED_ORDERS_SUCCESS,
  payload: response,
});
const fetchCompletedOrdersError = e => ({
  type: FETCH_COMPLETED_ORDERS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const loadCompletedOrdersData = (params, search) => async (dispatch, getState, sdk) => {
  dispatch(fetchCompletedOrdersRequest());

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };
  const onlyFilter = onlyFilterValues[params.tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${params.tab}`));
  }

  const { page = 1 } = parse(search);
  const perPage = 10;
  const transitions = getAllTransitionsForEveryProcess();
  const orderTransitions = transitions.filter(
    t => t !== 'transition/inquire' && t !== 'transition/expire-payment'
  );

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  try {
    // Step 1: Get total number of pages
    const numberOfPagesQuery = {
      only: onlyFilter,
      lastTransitions: orderTransitions,
      include: [],
      page: 1,
      perPage: 1,
    };

    const numberOfPagesQueryResponse = await sdk.transactions.query(numberOfPagesQuery);
    const numberOfPages = Math.ceil(numberOfPagesQueryResponse?.data?.meta?.totalItems / 100) || 0;

    if (numberOfPages === 0) {
      dispatch(addMarketplaceEntities(emptyResponse));
      dispatch(fetchCompletedOrdersSuccess(emptyResponse));
      return emptyResponse;
    }

    // Step 2: Fetch transactions in batches
    const getTransactions = async page => {
      const apiQueryParams = {
        only: onlyFilter,
        lastTransitions: orderTransitions,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'listing.currentStock',
          'messages',
          'listing.images',
        ],
        'fields.transaction': [
          'processName',
          'lastTransition',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'lineItems',
          'metadata',
          'protectedData',
        ],
        'fields.listing': [
          'title',
          'availabilityPlan',
          'publicData.listinglocation',
          'publicData.location',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page: page + 1,
        perPage: 100,
      };

      return await sdk.transactions.query(apiQueryParams);
    };

    const transactionBatches = await mapLimit([...Array(numberOfPages).keys()], 5, getTransactions);

    // Step 3: Combine all transactions and included data
    const allTransactions = transactionBatches
      .reduce((a, txb) => a.concat(txb?.data?.data || []), [])
      .filter(tx => new Date(tx.attributes.protectedData.orderData?.getbyDate) < today);

    const allIncluded = transactionBatches.reduce(
      (a, txb) => a.concat(txb?.data?.included || []),
      []
    );

    // Step 4: Sort transactions by getbyDate
    const sortedTransactions = allTransactions.sort((a, b) => {
      const dateA = new Date(a?.attributes?.protectedData?.orderData?.getbyDate);
      const dateB = new Date(b?.attributes?.protectedData?.orderData?.getbyDate);
      return dateB - dateA;
    });

    // Step 5: Apply pagination
    const paginatedTransactions = sortedTransactions.slice((page - 1) * perPage, page * perPage);
    const totalPages = Math.ceil(sortedTransactions.length / perPage);

    const finalResponse = {
      status: 200,
      statusText: '',
      data: {
        data: sortedTransactions,
        included: allIncluded,
        meta: {
          page: page,
          perPage: perPage,
          totalItems: sortedTransactions.length,
          totalPages: totalPages,
        },
      },
    };

    dispatch(addMarketplaceEntities(finalResponse));
    dispatch(fetchCompletedOrdersSuccess(finalResponse));

    return finalResponse;
  } catch (error) {
    dispatch(fetchCompletedOrdersError(storableError(error)));
    throw error;
  }
};

export const loadMessagesData = (params, search) => async (dispatch, getState, sdk) => {
  dispatch(fetchMessagesRequest());

  const { tab } = params;
  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };
  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  const { page = 1 } = parse(search);
  const perPage = 10; // Number of transactions per page

  try {
    // Step 1: Fetch transactions in batches until all data is retrieved

    // Get number of pages
    const numberOfPagesQuery = {
      only: onlyFilter,
      include: [],
      page: 1,
      perPage: 1, // Large batch size to reduce the number of calls
    };

    const numberOfPagesQueryResponse = await sdk.transactions.query(numberOfPagesQuery);
    const numberOfPages = Math.ceil(numberOfPagesQueryResponse?.data?.meta?.totalItems / 100) || 0;
    // If there are no transactions, return default empty response
    if (numberOfPages === 0) {
      dispatch(addMarketplaceEntities(emptyResponse));
      dispatch(fetchMessagesSuccess(emptyResponse));
      return emptyResponse;
    }

    const getTransactions = async page => {
      const apiQueryParams = {
        only: onlyFilter,
        // include: ['listing', 'provider', 'customer', 'booking', 'messages'],
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'listing.currentStock',
          'messages',
          'listing.images',
        ],
        'fields.transaction': [
          'processName',
          'lastTransition',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'lineItems',
          'metadata',
          'protectedData',
        ],
        'fields.listing': [
          'title',
          'availabilityPlan',
          'publicData.listinglocation',
          'publicData.location',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page: page + 1,
        perPage: 100, // Large batch size to reduce the number of calls
      };

      return await sdk.transactions.query(apiQueryParams);
    };
    const transactionBatches = await mapLimit([...Array(numberOfPages).keys()], 5, getTransactions);

    const allTransactions = transactionBatches
      .reduce((a, txb) => a.concat(txb?.data?.data || []), [])
      .filter(tx => {
        return (
          tx.relationships && tx.relationships.messages && tx.relationships.messages.data.length > 0
        );
      });

    const allIncluded = transactionBatches.reduce(
      (a, txb) => a.concat(txb?.data?.included || []),
      []
    );

    const allIncludedMessages = allIncluded.filter(i => i.type === 'message');
    const messageIdCreatedAtMap = {};
    for (const t of allIncludedMessages) {
      messageIdCreatedAtMap[t?.id?.uuid] = t?.attributes?.createdAt;
    }

    const transactionsWithMessages = allTransactions.map(transaction => {
      const messageIds = transaction?.relationships?.messages?.data?.map(
        message => message?.id?.uuid
      );
      const createdAtDates = messageIds
        .map(message => messageIdCreatedAtMap[message])
        .sort(function(a, b) {
          return b.getTime() - a.getTime();
        });

      return {
        ...transaction,
        attributes: {
          ...transaction.attributes,
          latestMessageDate: createdAtDates[0],
        },
      };
    });

    // Step 3: Remove transactions that do not have messages
    const filteredTransactions = transactionsWithMessages.filter(tx => tx !== null);

    // Step 4: Sort transactions globally by the latest message date
    const sortedTransactions = sortedTransactionsByLatestMessage(filteredTransactions);

    // Step 5: Apply pagination to the sorted list
    const paginatedTransactions = sortedTransactions.slice((page - 1) * perPage, page * perPage);
    const totalPages = Math.ceil(sortedTransactions.length / perPage);

    // transactionResponse.data.data = paginatedTransactions;
    // transactionResponse.data.meta = {
    //   page: page,
    //   perPage: perPage,
    //   totalItems: sortedTransactions.length,
    //   totalPages: totalPages,
    // }
    const finalResponse = {
      status: 200, // You can adjust this if needed
      statusText: '', // Add any meaningful text if necessary
      data: {
        data: sortedTransactions,
        included: allIncluded,
        meta: {
          page: page,
          perPage: 10,
          totalItems: sortedTransactions.length,
          totalPages: totalPages,
        },
      },
    };

    dispatch(addMarketplaceEntities(finalResponse));

    dispatch(fetchMessagesSuccess(finalResponse));

    return finalResponse;
  } catch (error) {
    dispatch(fetchMessagesError(storableError(error)));
    throw error;
  }
};

const getConfirmPaymentDate = transaction => {
  const confirmPayment = transaction.attributes.transitions.find(
    t => t.transition === 'transition/confirm-payment'
  );
  return confirmPayment ? new Date(confirmPayment.createdAt) : null;
};

export const loadData = (params, search) => async (dispatch, getState, sdk) => {
  const { tab, subTab, sortFilter } = params;

  if (subTab === 'inquiries') {
    return dispatch(loadMessagesData(params, search));
  }
  if (subTab === 'completedOrders') {
    return dispatch(loadCompletedOrdersData(params, search));
  }

  dispatch(fetchOrdersOrSalesRequest());
  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
  };
  const onlyFilter = onlyFilterValues[tab];
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  const { page = 1 } = parse(search);
  const perPage = 10;
  const transitions = getAllTransitionsForEveryProcess();
  const orderTransitions =
    subTab === 'inquiries'
      ? transitions
      : transitions.filter(t => t !== 'transition/inquire' && t !== 'transition/expire-payment');
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  try {
    // Step 1: Get total number of pages
    const numberOfPagesQuery = {
      only: onlyFilter,
      lastTransitions: orderTransitions,
      include: [],
      page: 1,
      perPage: 1,
    };

    const numberOfPagesQueryResponse = await sdk.transactions.query(numberOfPagesQuery);
    const numberOfPages = Math.ceil(numberOfPagesQueryResponse?.data?.meta?.totalItems / 100) || 0;

    if (numberOfPages === 0) {
      dispatch(addMarketplaceEntities(emptyResponse));
      dispatch(fetchOrdersOrSalesSuccess(emptyResponse));
      return emptyResponse;
    }

    // Step 2: Fetch transactions in batches
    const getTransactions = async page => {
      const apiQueryParams = {
        only: onlyFilter,
        lastTransitions: orderTransitions,
        include: [
          'customer',
          'customer.profileImage',
          'provider',
          'provider.profileImage',
          'listing',
          'listing.currentStock',
          'booking',
          'reviews',
          'messages',
          'reviews.author',
          'reviews.subject',
          'listing.images',
        ],
        'fields.transaction': [
          'processName',
          'lastTransition',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'lineItems',
          'metadata',
          'protectedData',
        ],
        'fields.listing': [
          'title',
          'availabilityPlan',
          'publicData.listinglocation',
          'publicData.location',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page: page + 1,
        perPage: 100,
      };

      return await sdk.transactions.query(apiQueryParams);
    };

    const transactionBatches = await mapLimit([...Array(numberOfPages).keys()], 5, getTransactions);

    // Step 3: Combine transactions and included data
    const allTransactions = transactionBatches
      .reduce((a, txb) => a.concat(txb?.data?.data || []), [])
      .filter(tx => new Date(tx.attributes.protectedData.orderData?.getbyDate) >= today);

    const allIncluded = transactionBatches.reduce(
      (a, txb) => a.concat(txb?.data?.included || []),
      []
    );

    // Step 4: Sort transactions based on sortFilter
    if (sortFilter === 'getbyDate') {
      allTransactions.sort((a, b) => {
        const dateA = new Date(a?.attributes?.protectedData?.orderData?.getbyDate);
        const dateB = new Date(b?.attributes?.protectedData?.orderData?.getbyDate);
        return dateA - dateB;
      });
    } else {
      allTransactions.sort((a, b) => {
        const dateA = getConfirmPaymentDate(a);
        const dateB = getConfirmPaymentDate(b);
        return dateB - dateA; // Descending by "confirm-payment" date
      });
    }

    // Step 5: Paginate sorted transactions
    const paginatedTransactions = allTransactions.slice((page - 1) * perPage, page * perPage);
    const totalPages = Math.ceil(allTransactions.length / perPage);
    const finalResponse = {
      status: 200,
      statusText: '',
      data: {
        data: allTransactions,
        included: allIncluded,
        meta: {
          page: page,
          perPage: perPage,
          totalItems: allTransactions.length,
          totalPages: totalPages,
        },
      },
    };

    dispatch(addMarketplaceEntities(finalResponse));
    dispatch(fetchOrdersOrSalesSuccess(finalResponse));

    return finalResponse;
  } catch (error) {
    dispatch(fetchOrdersOrSalesError(storableError(error)));
    throw error;
  }
};
