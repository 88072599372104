import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from '../../util/reactIntl';
import { IconArrowHead } from '..';
import { getPageNumbersArray } from '../PaginationLinks/PaginationLinks';

import css from './InboxPagination.module.css';

const InboxPagination = props => {
    const {
        className,
        pagination,
        setPage,
        page,
    } = props;

    const { totalPages } = pagination;
    const prevPage = page > 1 ? page - 1 : null;
    const nextPage = page < totalPages ? page + 1 : null;

    const classes = classNames(css.root, className);

    const pageNumbersNavLinks = !!pagination ? getPageNumbersArray(page, pagination.totalPages).map(v => {
      const isCurrentPage = v === page;
      const pageClassNames = classNames(css.toPageLink, { [css.currentPage]: isCurrentPage });
      return (
        <div 
          className={pageClassNames}
          onClick={() => setPage(v)}
        >
          {v}
        </div>
      )
    }) : [];
  
    const pageNumberListClassNames = classNames(
      css.pageNumberList,
      css[`pageNumberList${pageNumbersNavLinks.length}Items`]
    );  

    const prevLinkEnabled = (
        <div 
          className={css.prev}
          onClick={() => setPage(page - 1)}
        >
          <IconArrowHead direction="left" size="big" rootClassName={css.arrowIcon} />
        </div>
      );
    
      const prevLinkDisabled = (
        <div className={css.prev}>
          <IconArrowHead
            direction="left"
            size="big"
            rootClassName={classNames(css.arrowIcon, css.disabled)}
          />
        </div>
      );
    
      const nextLinkEnabled = (
        <div 
          className={css.next}
          onClick={() => setPage(page + 1)}
        >
          <IconArrowHead direction="right" size="big" rootClassName={css.arrowIcon} />
        </div>
      );
    
      const nextLinkDisabled = (
        <div className={css.next}>
          <IconArrowHead
            direction="right"
            size="big"
            rootClassName={classNames(css.arrowIcon, css.disabled)}
          />
        </div>
      );

    return (
        <nav className={classes}>
            {prevPage ? prevLinkEnabled : prevLinkDisabled}
            <div className={pageNumberListClassNames}>{pageNumbersNavLinks}</div>
            {nextPage ? nextLinkEnabled : nextLinkDisabled}
        </nav>
    );
}

InboxPagination.defaultProps = {
    className: '',
    pageName: '',
    page: 1,
};

const { string, object, func, number } = PropTypes;

InboxPagination.propTypes = {
    className: string,
    pageName: string,
    pagination: object,
    setPage: func,
    page: number,
}

export default injectIntl(InboxPagination);
