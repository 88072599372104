export class LoggingAnalyticsHandler {
  trackPageView(url) {
    console.log('Analytics page view:', url);
  }
}

// Google Analytics 4 (GA4) using gtag.js script, which is included in util/includeScripts.js
export class GoogleAnalyticsHandler {
  trackPageView(canonicalPath, previousPath) {
    // GA4 property. Manually send page_view events
    // https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    // Note 1: You should turn "Enhanced measurement" off.
    //         It attaches own listeners to elements and that breaks in-app navigation.
    // Note 2: If previousPath is null (just after page load), gtag script sends page_view event automatically.
    //         Only in-app navigation needs to be sent manually from SPA.
    // Note 3: Timeout is needed because gtag script picks up <title>,
    //         and location change event happens before initial rendering.
    if (previousPath && window.gtag) {
      window.setTimeout(() => {
        window.gtag('event', 'page_view', {
          page_path: canonicalPath,
        });
      }, 300);
    }
  }
}

// export class FacebookPixelHandler {
//   constructor(pixelId) {
//     const options = {
//       autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//       debug: false, // enable logs
//     };
//     import('react-facebook-pixel')
//       .then(module => module.default)
//       .then(ReactPixel => {
//         ReactPixel.init(pixelId, options)
//         ReactPixel.pageView(); // For tracking page view
//         ReactPixel.track(event, data);
//         ReactPixel.trackCustom(event, data);
//         this.ReactPixel = ReactPixel
//       })
//   }

//   //PageView de facebook pixel
//   trackPageView() {
//     if (this.ReactPixel) {
//       return this.ReactPixel.pageView();
//     }
//   }
//   // trackDefaultEvent(){
//   //   if (this.ReactPixel) {
//   //     return this.ReactPixel.track(event, data);
//   //   }
//   // }
//   // trackCustomEvent(){
//   //   if (this.ReactPixel) {
//   //     return this.ReactPixel.trackCustom(event, data);
//   //   }
//   // }

// }
